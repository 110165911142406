import { AxiosError } from 'axios';
import { createEffect, createEvent, createStore } from 'effector';

import variables from '@vkph/ui/providers/theme/variables';

import { endpoints } from '../../endpoints';
import { ColorTheme, ThemeState } from '../../types/theme';
import { abstractStorageFactory } from '../../utils/effector';
import { saveThemeColors, SaveThemeColorsParams } from './api';

export const colorNameDictionary: Record<string, string> = {
  colorBrand: 'Основной цвет',
  colorHoverOnBrand: 'Цвет при наведении',
  colorInputOnBrand: 'Цвет элементов поверх основного цвета',
  colorLinkBrand: 'Цвет ссылок',
  colorTextOnBrand: 'Цвет текста поверх основного цвета',
  colorBrandFaded: 'Цвет фокуса, заливка аватара',
  colorBrand04: 'Основной цвет с прозрачностью',
  colorBrand08: 'Второстепенный цвет кнопки',
  colorBrand16: 'Второстепенный цвет кнопки при наведении',
  colorBrand32: 'Основной цвет с прозрачностью',
};

export const defaultValueColors: ColorTheme[] = [
  {
    name: 'colorBrand',
    color: variables.themeVariables.colorBrand,
    description:
      'Фирменный цвет, используется в топбаре, кнопках основного действия, блоках, которые необходимо выделить.',
  },
  {
    name: 'colorInputOnBrand',
    color: variables.themeVariables.colorInputOnBrand,
    description:
      'Цвет интерактивных элементов на фирменном фоне: например, поле поиска в топбаре, кнопка уведомлений.',
  },
  {
    name: 'colorHoverOnBrand',
    color: variables.themeVariables.colorHoverOnBrand,
    description:
      'Цвет при наведении на интерактивные элементы с цветом colorBrandPrimary (кнопки) и colorInputOnPrimary (поле поиска в топбаре)',
  },
  {
    name: 'colorLinkBrand',
    color: variables.themeVariables.colorLinkBrand,
    description: 'Цвет для текстовых ссылок',
  },
  {
    name: 'colorBrandFaded',
    color: variables.themeVariables.colorBrandFaded,
    description: 'Цвет сердечек в посте по умолчанию и рамка фокуса интерактивных элементов',
  },
  {
    name: 'colorBrand32',
    color: variables.themeVariables.colorBrand32,
    description:
      'Вариации брендового цвета с прозрачностью для использования во второстепенных интерактивных элементах',
  },
  {
    name: 'colorBrand16',
    color: variables.themeVariables.colorBrand16,
    description: '',
  },
  {
    name: 'colorBrand08',
    color: variables.themeVariables.colorBrand08,
    description: '',
  },
  {
    name: 'colorBrand04',
    color: variables.themeVariables.colorBrand04,
    description: '',
  },
  {
    name: 'colorTextOnBrand',
    color: variables.themeVariables.colorTextOnBrand,
    description:
      'Цвет основного текста и иконок на брендовом фоне (кнопки, текст на плашках, топбаре). НЕ используем на элементах, цвет которых статичный: тултипы, карточки новостей и т.д.',
  },
];

export const getThemeStorage = () => {
  const storage = abstractStorageFactory<ColorTheme[], ColorTheme[], ColorTheme[]>({
    endpointBuilder: endpoints.pages.colors,
    defaultValue: defaultValueColors,
    dataMapper: (colors) => colors,
    cancelPendingRequestOnFetch: true,
  });

  const saveThemeColorsEffect = createEffect<SaveThemeColorsParams, ColorTheme[], AxiosError>((params) =>
    saveThemeColors<ColorTheme[]>(params).then((response) => response.data),
  );

  const themeState = createStore<ThemeState>({
    colors: [],
    isUserColorsFilled: false,
  });

  const setColorThemeEvent = createEvent<Omit<ColorTheme, 'description'>>();
  const cancelEditThemeEvent = createEvent();
  const setInitialColorsThemeEvent = createEvent();

  themeState
    .on(storage.store, (state, { data: colors }) => ({
      ...state,
      colors: colors.length > 0 ? colors : defaultValueColors,
    }))
    .on(storage.fetchEffect.done, (state) => ({ ...state, isUserColorsFilled: true }))
    .on(saveThemeColorsEffect, (state) => ({ ...state, isUserColorsFilled: false }))
    .on(saveThemeColorsEffect.doneData, (state, colors) => ({
      ...state,
      isUserColorsFilled: true,
      colors: colors.length > 0 ? colors : defaultValueColors,
    }))
    .on(setColorThemeEvent, (state, { color, name }) => ({
      ...state,
      colors: state.colors.map((colorItem) =>
        colorItem.name === name ? { ...colorItem, color } : colorItem,
      ),
    }))
    .on(cancelEditThemeEvent, (state) => {
      const { data: colors } = storage.store.getState();

      return { ...state, colors };
    })
    .on(setInitialColorsThemeEvent, (state) => {
      return { ...state, colors: defaultValueColors };
    });

  return {
    storage,
    themeState,
    setColorThemeEvent,
    cancelEditThemeEvent,
    setInitialColorsThemeEvent,
    saveThemeColorsEffect,
    refetchWithLastParams: storage.refetchWithLastParams,
  };
};

export const themeStorage = getThemeStorage();
