import { Layout, UiEmpty } from '@vkph/ui';
import React, { useEffect, FC, PropsWithChildren } from 'react';

import { useAbstractStorage, useLocalStorage } from '../../../hooks';
import { settingsAuthConfigStorage, settingsLayoutsConfigStorage } from '../../../store/settings';
import { SettingsConfigProvider } from '../provider/SettingsConfigProvider';

export const SettingsConfigService: FC<PropsWithChildren> = (props) => {
  const { data: settingsLayoutsData, loading: isSettingsLayoutLoading } = useAbstractStorage(
    settingsLayoutsConfigStorage.storage,
    {
      autoFetchAndRefetch: true,
    },
  );

  const { data: settingsAuthData, loading: isSettingsAuthLoading } = useAbstractStorage(
    settingsAuthConfigStorage.storage,
    {
      autoFetchAndRefetch: !isSettingsLayoutLoading,
    },
  );

  const [authData, setAuthData] = useLocalStorage('settingsAuthData', settingsAuthData);

  useEffect(() => {
    if (settingsAuthData) {
      setAuthData(settingsAuthData);
    }
  }, [settingsAuthData]);

  if (isSettingsAuthLoading && !authData) {
    return <Layout.Loading />;
  }

  if (authData) {
    return <SettingsConfigProvider {...props} config={{ layouts: settingsLayoutsData }} />;
  }

  return <UiEmpty.Feed emptyMessage={{ header: 'Авторизация не может быть выполнена' }} />;
};
