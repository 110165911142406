import { UiUploadFile } from '@vkph/ui';
import { AxiosError } from 'axios';
import { createEffect, createEvent } from 'effector';

import { endpoints } from '../../endpoints';
import { DictPaginated, OrderingParams, PaginationParams } from '../../types/api';
import {
  AttachmentEntryId,
  CommentObjectId,
  UserIdModel,
  FileModel,
  FileStorageCategoryModel,
  FileStorageCropImageModel,
  FileStorageEntryFavoriteModel,
  FileStorageEntryHierarchyModel,
  FileStorageEntryHistoryModel,
  FileStorageEntryId,
  FileStorageEntryInfoModel,
  FileStorageEntryModel,
  FileStorageEntryType,
  FileStorageEntryVersionModel,
  FileStorageFileModel,
  FileStorageFolderExtensionsModel,
  FileStorageFolderUserRolesModel,
  FileStorageListEntryModel,
  FileStorageObjectModel,
  PreloadedFileModel,
} from '../../types/models';
import {
  buildEndpointWithQueryParams,
  BaseFileSizeUploadParams,
  FileSizeUnit,
  getFileSizeUploadError,
} from '../../utils';
import {
  abstractFilesUploadFactory,
  FileValidationHandler,
  UploadFile,
  abstractStorageFactory,
  AbstractStorageStoredData,
} from '../../utils/effector';
import {
  addObjectToFavorite,
  BaseFileStorageIdParams,
  createFileStorageCategory,
  CreateFileStorageCategoryParams,
  createFileStorageFolder,
  CreateFileStorageFolderParams,
  cropFileStorageImage,
  deleteFileStorageCategory,
  DeleteFileStorageCategoryParams,
  DeleteFileStorageEntriesParams,
  deleteFileStorageEntry,
  deleteFileStorageEntryVersion,
  DeleteFileStorageEntryVersionParams,
  FileStorageApiVersions,
  FileStorageCropImageUploadParams,
  fileStorageFileShare,
  GetFileStorageFolderExtensionsParams,
  GetFileStorageFolderUserRolesParams,
  MoveFileStorageEntryParams,
  patchFileStorageCategory,
  PatchFileStorageCategoryParams,
  patchFileStorageEntry,
  patchFileStorageFolder,
  putFileStorageEntry,
  PutFileStorageEntryParams,
  removeObjectFromFavorite,
  RenameFileStorageEntryParams,
  restoreFileStorageEntryVersion,
  RestoreFileStorageEntryVersionParams,
  setFileStorageFolderExtensions,
  SetFileStorageFolderExtensionsParams,
  setFileStorageFolderUserRole,
  SetFileStorageFolderUserRoleParams,
  transferFileStorageEntries,
  TransferFileStorageEntriesParams,
  undeleteFileStorageEntryVersion,
  UndeleteFileStorageEntryVersionParams,
  UpdateFileStorageFolderParams,
  uploadFileStorageAttachment,
  UploadFileStorageAttachmentParams,
  UploadFileStorageMultipleAttachmentsParams,
} from './api';

export enum FileStorageOrderingParams {
  TypeAsc = 'type',
  TypeDesc = '-type',
  NameAsc = 'name',
  NameDesc = '-name',
  SizeAsc = 'size',
  SizeDesc = '-size',
}

export type AddAttachmentParams = {
  id: AttachmentEntryId | FileStorageEntryId;
  size: number;
  name: string;
  url?: string;
};

export type GetAttachmentsParams = {
  ids?: AttachmentEntryId[];
};

export type FileStorageParentsParams = {
  upto: FileStorageEntryId;
};

export type FileStorageFileShareParams = {
  fileId: FileStorageEntryId;
  targets: UserIdModel[];
};

export type PaginatedFileStorageIdParams = BaseFileStorageIdParams & Partial<PaginationParams>;

export const uploadFileStorageAttachmentEffectFactory = <R = FileModel>(
  apiVersion = FileStorageApiVersions.v2,
) =>
  createEffect<UploadFileStorageAttachmentParams, R, AxiosError>((params) =>
    uploadFileStorageAttachment<R>({ apiVersion, ...params }),
  );

export type UploadFileStorageAttachmentEffect<T = FileModel> = ReturnType<
  typeof uploadFileStorageAttachmentEffectFactory<T>
>;

type GetFileStorageParams<StoreFile> = {
  defaultValue?: UploadFile<StoreFile>[];
  sizeValidationConfig?: BaseFileSizeUploadParams;
};

export const uploadFileStorageMultipleAttachmentsEffectFactory = () =>
  createEffect<UploadFileStorageMultipleAttachmentsParams, FileStorageFileModel[], AxiosError>(
    ({ files, onUploadProgress, apiVersion }) =>
      Promise.all(
        files.map((fileToUpload) =>
          uploadFileStorageAttachment<PreloadedFileModel>({
            file: fileToUpload.rawFile,
            onUploadProgress:
              onUploadProgress && ((percent: number) => onUploadProgress(fileToUpload.id, percent)),
            apiVersion,
          }).then((preloadedFile) => ({
            id: preloadedFile.storageObject,
            createdAt: preloadedFile.createdAt,
            file: preloadedFile.fileUrl,
            size: preloadedFile.size,
            name: preloadedFile.name,
            shortName: preloadedFile.shortName,
            type: 1,
            parent: '',
          })),
        ),
      ),
  );

export type UploadFileStorageMultipleAttachmentsEffect = ReturnType<
  typeof uploadFileStorageMultipleAttachmentsEffectFactory
>;

export const uploadImageCropEffect = createEffect<
  FileStorageCropImageUploadParams,
  FileStorageCropImageModel,
  AxiosError
>((params) => cropFileStorageImage(params).then(({ data }) => data));

export const createUpdateFileStorageEntryEffect = createEffect<
  PutFileStorageEntryParams,
  FileStorageFileModel,
  AxiosError
>((params) => putFileStorageEntry(params).then(({ data }) => data));

export const transferFileStorageEntriesEffect = createEffect<
  TransferFileStorageEntriesParams,
  void,
  AxiosError
>((params) => transferFileStorageEntries(params).then(({ data }) => data));

export type FileStorageListParams = {
  entryId?: FileStorageEntryId;
  search?: string;
  type?: FileStorageEntryType;
  categories?: string;
};

export type FileStorageOrdered = { ordering?: (OrderingParams | FileStorageOrderingParams)[] };
export type FileStorageListStorageParams = PaginationParams & FileStorageListParams & FileStorageOrdered;

export const getFileStorageListStorage = (fileStorageEntryId: FileStorageEntryId) => {
  const storage = abstractStorageFactory<
    DictPaginated<FileStorageListEntryModel>,
    FileStorageListEntryModel[],
    FileStorageListEntryModel[],
    FileStorageListStorageParams
  >({
    endpointBuilder: ({ entryId, ...params }) =>
      buildEndpointWithQueryParams(
        endpoints.filestorage.folderEntryIdObjects(entryId || fileStorageEntryId),
        {
          ...params,
          ordering: params?.ordering?.join(','),
        },
      ),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  const { store } = storage;

  const deleteFileStorageEntryEvent = createEvent<DeleteFileStorageEntriesParams>();
  const moveFileStorageEntryEvent = createEvent<MoveFileStorageEntryParams>();
  const renameFileStorageEntryEvent = createEvent<RenameFileStorageEntryParams>();
  const createFileStorageFolderEvent = createEvent<FileStorageEntryModel>();
  const updateFileStorageFolderEvent = createEvent<FileStorageEntryModel>();
  const createUpdateFileStorageFileEvent = createEvent<FileStorageFileModel>();

  const removeEntriesFromStorage = (
    state: AbstractStorageStoredData<FileStorageListEntryModel[], FileStorageListEntryModel[]>,
    entryIds: FileStorageEntryId[],
  ) => {
    const { data } = state;
    const entriesToRemove = new Set(entryIds);

    const filteredData = data.filter(
      ({ id: filteredFileStorageEntryId }) => !entriesToRemove.has(filteredFileStorageEntryId),
    );

    return { ...state, data: filteredData };
  };

  store
    .on(deleteFileStorageEntryEvent, (state, { objectIds }) => removeEntriesFromStorage(state, objectIds))
    .on(moveFileStorageEntryEvent, (state, { id }) => removeEntriesFromStorage(state, [id]))
    .on(renameFileStorageEntryEvent, (state, { id, name }) => {
      const { data } = state;
      const updatedData = data.map((entry) => {
        if (entry.id === id) {
          return {
            ...entry,
            name,
          };
        }

        return entry;
      });

      return { ...state, data: updatedData };
    })
    .on(createFileStorageFolderEvent, (state, folder) => {
      const { data } = state;
      const entry = {
        ...folder,
        size: 0,
        foldersCount: folder.foldersCount || 0,
        filesCount: folder.filesCount || 0,
        objectViewsCount: 0,
        reactions: [],
        commentsCount: 0,
      } satisfies FileStorageListEntryModel;

      return { ...state, data: [entry, ...data] };
    })
    .on(updateFileStorageFolderEvent, (state, folder) => {
      const { data } = state;

      const newFolder = {
        ...folder,
        size: 0,
        foldersCount: folder.foldersCount || 0,
        filesCount: folder.filesCount || 0,
        objectViewsCount: 0,
        reactions: [],
        commentsCount: 0,
      } satisfies FileStorageListEntryModel;

      const newData = data.map((entry) => {
        return entry.id === newFolder.id ? newFolder : entry;
      });

      return { ...state, data: newData };
    })
    .on(createUpdateFileStorageFileEvent, (state, file) => {
      const { data } = state;
      const filteredData = data.filter((entry) => entry.id !== file.id);

      const entry = {
        ...file,
        objectViewsCount: 0,
        reactions: [],
        commentsCount: 0,
        foldersCount: 0,
        filesCount: 0,
      } satisfies FileStorageListEntryModel;

      return { ...state, data: [entry, ...filteredData] };
    });

  const deleteFileStorageEntryEffect = createEffect<DeleteFileStorageEntriesParams, void, AxiosError>(
    (params) =>
      deleteFileStorageEntry(params).then(() => {
        deleteFileStorageEntryEvent(params);
      }),
  );

  // TODO:: Добавить тип ответа когда будет готов бэк (B2BCORE-4091)
  const moveFileStorageEntryEffect = createEffect<MoveFileStorageEntryParams, unknown, AxiosError>((params) =>
    patchFileStorageEntry(params).then(() => {
      moveFileStorageEntryEvent(params);
    }),
  );

  // TODO:: Добавить тип ответа когда будет готов бэк (B2BCORE-4091)
  const renameFileStorageEntryEffect = createEffect<RenameFileStorageEntryParams, unknown, AxiosError>(
    (params) =>
      patchFileStorageEntry(params).then(() => {
        renameFileStorageEntryEvent(params);
      }),
  );

  const createFileStorageFolderEffect = createEffect<
    CreateFileStorageFolderParams,
    FileStorageEntryModel,
    AxiosError
  >((params) => createFileStorageFolder(params).then(({ data }) => createFileStorageFolderEvent(data)));

  const updateFileStorageFolderEffect = createEffect<
    UpdateFileStorageFolderParams,
    FileStorageEntryModel,
    AxiosError
  >((params) => patchFileStorageFolder(params).then(({ data }) => updateFileStorageFolderEvent(data)));

  return {
    storage,
    createUpdateFileStorageFileEvent,
    deleteFileStorageEntryEffect,
    moveFileStorageEntryEffect,
    renameFileStorageEntryEffect,
    createFileStorageFolderEffect,
    updateFileStorageFolderEffect,
  };
};

export type GetFileStorageListStorage = ReturnType<typeof getFileStorageListStorage>;

export const getFileStorageSearchListStorage = (fileStorageEntryId: FileStorageEntryId) => {
  const storage = abstractStorageFactory<
    DictPaginated<FileStorageListEntryModel>,
    FileStorageListEntryModel[],
    FileStorageListEntryModel[],
    FileStorageListStorageParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.filestorage.folderEntryIdFilesSearch(fileStorageEntryId), {
        ...params,
        ordering: params?.ordering?.join(','),
      }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return {
    storage,
  };
};

export type GetFileStorageSearchListStorage = ReturnType<typeof getFileStorageSearchListStorage>;

export const getFileStorageHierarchyStorage = (fileStorageEntryId: FileStorageEntryId) => {
  const storage = abstractStorageFactory<
    FileStorageEntryHierarchyModel[],
    FileStorageEntryHierarchyModel[],
    FileStorageEntryHierarchyModel[],
    FileStorageParentsParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.filestorage.objectEntryIdHierarchy(fileStorageEntryId), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return {
    storage,
  };
};

export const getFileStorageSharedFolderStorage = () => {
  const storage = abstractStorageFactory<FileStorageObjectModel, FileStorageObjectModel, null, void>({
    endpointBuilder: () => buildEndpointWithQueryParams(endpoints.filestorage.folderSharedFolder()),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return {
    storage,
  };
};

export interface FileStorageToggleFavoriteParams extends BaseFileStorageIdParams {
  isFavorite: boolean;
}

export const getFileStorageFolderInfoStorage = () => {
  const storage = abstractStorageFactory<
    FileStorageEntryInfoModel,
    FileStorageEntryInfoModel,
    null,
    BaseFileStorageIdParams
  >({
    endpointBuilder: ({ id }) => endpoints.filestorage.folderEntryId(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getFileStorageEntryInfoStorage = () => {
  const storage = abstractStorageFactory<
    FileStorageEntryInfoModel,
    FileStorageEntryInfoModel,
    null,
    BaseFileStorageIdParams
  >({
    endpointBuilder: ({ id }) => endpoints.filestorage.fileEntryIdRevision(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const toggleFavoriteEffect = createEffect<
    FileStorageToggleFavoriteParams,
    FileStorageEntryFavoriteModel | void,
    AxiosError
  >(({ isFavorite, ...params }) => {
    if (isFavorite) {
      return removeObjectFromFavorite(params).then(({ data }) => data);
    }

    return addObjectToFavorite(params).then(({ data }) => data);
  });

  storage.store.on(toggleFavoriteEffect, (state) =>
    state.data ? { ...state, data: { ...state.data, isFavorite: !state.data.isFavorite } } : state,
  );

  return { storage, toggleFavoriteEffect };
};

export type GetFileStorageEntryInfoStorage = ReturnType<typeof getFileStorageEntryInfoStorage>;

export enum ChangeCommentsCountType {
  Decrease = 'decrease',
  Increase = 'increase',
}

type ChangeCommentsCountEvent = {
  type: ChangeCommentsCountType;
  objectId: CommentObjectId;
};

export const getFileStorageEntryVersionListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<FileStorageEntryVersionModel>,
    FileStorageEntryVersionModel[],
    FileStorageEntryVersionModel[],
    PaginatedFileStorageIdParams
  >({
    endpointBuilder: ({ id, ...paginationParams }) =>
      buildEndpointWithQueryParams(endpoints.filestorage.fileEntryIdVersionsRevision(id), paginationParams),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
    cancelPendingRequestOnFetch: true,
    shouldAppendData: true,
  });

  const changeCommentsCountEvent = createEvent<ChangeCommentsCountEvent>();

  storage.store.on(changeCommentsCountEvent, (state, { type, objectId }) => ({
    ...state,
    data: state.data.map((version) => {
      if (version.versionId === objectId) {
        return {
          ...version,
          commentsCount:
            type === ChangeCommentsCountType.Decrease ? version.commentsCount - 1 : version.commentsCount + 1,
        };
      }

      return version;
    }),
  }));

  const toggleIsDeleted = (
    state: AbstractStorageStoredData<FileStorageEntryVersionModel[], FileStorageEntryVersionModel[]>,
    { params }: { params: DeleteFileStorageEntryVersionParams },
  ) => {
    const { revision } = params;

    return {
      ...state,
      data: state.data.map((version) => {
        if (version.revision === revision) {
          return {
            ...version,
            isDeleted: !version.isDeleted,
          };
        }

        return version;
      }),
    };
  };

  const deleteFileStorageEntryVersionEffect = createEffect<
    DeleteFileStorageEntryVersionParams,
    void,
    AxiosError
  >((params) => deleteFileStorageEntryVersion(params).then(({ data }) => data));

  const undeleteFileStorageEntryVersionEffect = createEffect<
    UndeleteFileStorageEntryVersionParams,
    string,
    AxiosError
  >((params) => undeleteFileStorageEntryVersion(params).then(({ data }) => data));

  const restoreFileStorageEntryVersionEffect = createEffect<
    RestoreFileStorageEntryVersionParams,
    FileStorageEntryVersionModel,
    AxiosError
  >((params) =>
    restoreFileStorageEntryVersion<FileStorageEntryVersionModel>(params).then(({ data }) => data),
  );

  storage.store.on(restoreFileStorageEntryVersionEffect.doneData, (state, fileStorageEntryVersion) => ({
    ...state,
    data: [fileStorageEntryVersion, ...state.data],
  }));

  storage.store.on(
    [deleteFileStorageEntryVersionEffect.done, undeleteFileStorageEntryVersionEffect.done],
    toggleIsDeleted,
  );

  return {
    storage,
    deleteFileStorageEntryVersionEffect,
    undeleteFileStorageEntryVersionEffect,
    restoreFileStorageEntryVersionEffect,
    changeCommentsCountEvent,
  };
};

export type GetFileStorageEntryVersionListStorage = ReturnType<typeof getFileStorageEntryVersionListStorage>;

export const createFileStorageCategoryEffect = createEffect<
  CreateFileStorageCategoryParams,
  FileStorageCategoryModel,
  AxiosError
>((params) => createFileStorageCategory(params).then(({ data }) => data));

export const updateFileStorageCategoryEffect = createEffect<
  PatchFileStorageCategoryParams,
  FileStorageCategoryModel,
  AxiosError
>((params) => patchFileStorageCategory(params).then(({ data }) => data));

type GetFileStorageCategoriesParams = { entryId: FileStorageEntryId };

export const getFileStorageFolderCategoriesStorage = () => {
  const storage = abstractStorageFactory<
    FileStorageCategoryModel[],
    FileStorageCategoryModel[],
    FileStorageCategoryModel[],
    GetFileStorageCategoriesParams
  >({
    endpointBuilder: ({ entryId }) => endpoints.filestorage.folderEntryIdCategories(entryId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getFileStorageAllCategoriesStorage = () => {
  const storage = abstractStorageFactory<
    FileStorageCategoryModel[],
    FileStorageCategoryModel[],
    FileStorageCategoryModel[],
    GetFileStorageCategoriesParams
  >({
    endpointBuilder: ({ entryId }) => endpoints.filestorage.folderEntryIdAllowedCategories(entryId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const { store } = storage;

  const deleteFileStorageCategoryEffect = createEffect<DeleteFileStorageCategoryParams, void, AxiosError>(
    (params) => deleteFileStorageCategory(params).then(({ data }) => data),
  );

  const createFileStorageCategoryEvent = createEvent<FileStorageCategoryModel>();
  const updateFileStorageCategoryEvent = createEvent<FileStorageCategoryModel>();

  store
    .on(createFileStorageCategoryEvent, (state, newCategory) => ({
      ...state,
      data: [...state.data, newCategory],
    }))
    .on(updateFileStorageCategoryEvent, (state, updatedCategory) => ({
      ...state,
      data: state.data.map((category) =>
        category.id === updatedCategory.id ? { ...category, ...updatedCategory } : category,
      ),
    }))
    .on(deleteFileStorageCategoryEffect.done, (state, { params: { categoryId } }) => ({
      ...state,
      data: state.data.filter((category) => category.id !== categoryId),
    }));

  return {
    storage,
    createFileStorageCategoryEvent,
    updateFileStorageCategoryEvent,
    deleteFileStorageCategoryEffect,
  };
};

export type GetFileStorageCategoriesStorage = ReturnType<typeof getFileStorageAllCategoriesStorage>;

export const getFileStorageEntryHistoryStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<FileStorageEntryHistoryModel>,
    FileStorageEntryHistoryModel[],
    FileStorageEntryHistoryModel[],
    PaginatedFileStorageIdParams
  >({
    endpointBuilder: ({ id, ...paginationParams }) =>
      buildEndpointWithQueryParams(endpoints.filestorage.objectEntryIdHistory(id), paginationParams),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    shouldAppendData: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};

export type GetFileStorageEntryHistoryStorage = ReturnType<typeof getFileStorageEntryHistoryStorage>;

export const getFilesUploadStorage = (params: GetFileStorageParams<UiUploadFile> = {}) => {
  const { defaultValue = [], sizeValidationConfig } = params;

  const fileValidation: FileValidationHandler<UiUploadFile> = (file: UploadFile<UiUploadFile>) => {
    const validationConfig: BaseFileSizeUploadParams = sizeValidationConfig || {
      errorSizeMessage: 'Превышен допустимый размер 2 Гб',
      maxSize: 2,
      unitSize: FileSizeUnit.GB,
    };

    return getFileSizeUploadError({
      file: file.file,
      ...validationConfig,
    });
  };

  const storage = abstractFilesUploadFactory<PreloadedFileModel, UiUploadFile>({
    endpoint: endpoints.filestorage.fileUpload(),
    defaultValue,
    ...(sizeValidationConfig && { sizeValidationConfig }),
    dataMapper: (uploadedFile, beforeUploadFile) => {
      return {
        uid: uploadedFile.storageObject,
        name: beforeUploadFile.name,
      };
    },
    fileValidation,
  });

  return { storage };
};

export const getFileStorageFolderUserRoles = () => {
  const storage = abstractStorageFactory<
    FileStorageFolderUserRolesModel,
    FileStorageFolderUserRolesModel,
    null,
    GetFileStorageFolderUserRolesParams
  >({
    endpointBuilder: ({ entryId }) => endpoints.filestorage.folderEntryIdUserRoles(entryId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const fileStorageFolderSetUserRoleEffect = createEffect<
    SetFileStorageFolderUserRoleParams,
    void,
    AxiosError
  >((params) => setFileStorageFolderUserRole(params).then(({ data }) => data));

  return { storage, fileStorageFolderSetUserRoleEffect };
};

export const getFileStorageFolderExtensions = () => {
  const storage = abstractStorageFactory<
    FileStorageFolderExtensionsModel,
    FileStorageFolderExtensionsModel,
    null,
    GetFileStorageFolderExtensionsParams
  >({
    endpointBuilder: ({ entryId }) => endpoints.filestorage.folderEntryIdExtensions(entryId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const setFileStorageFolderExtensionsEffect = createEffect<
    SetFileStorageFolderExtensionsParams,
    FileStorageFolderExtensionsModel,
    AxiosError
  >((params) => setFileStorageFolderExtensions(params).then(({ data }) => data));

  return { storage, setFileStorageFolderExtensionsEffect };
};

export const fileStorageFileShareEffect = createEffect<FileStorageFileShareParams, void, AxiosError>(
  (params) => fileStorageFileShare(params).then(({ data }) => data),
);
